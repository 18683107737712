import React, { useState } from 'react'
import { TagInput } from 'reactjs-tag-input'

function InputComponent({ value, onChange, ...inputProps }) {
  const [state, setstate] = useState(value)

  const onTagsChanged = (ev) => {
    setstate(ev)
    if (onChange) {
      onChange(ev)
    }
  }

  return <TagInput placeholder="Informe uma palavra e pressione Enter" tags={state} onTagsChanged={onTagsChanged} {...inputProps} className="form-control" />
}

export default InputComponent
