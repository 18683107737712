import React, { Fragment } from 'react'
import Hamburger from 'react-hamburgers'
import { useDispatch, useSelector } from 'react-redux'
import AppMobileMenu from '../AppMobileMenu'

export default function AppLogo() {
  const dispatch = useDispatch()
  const enableClosedSidebar = useSelector((state) => state.enableClosedSidebar)

  function toggleEnableClosedSidebar() {
    dispatch({ type: 'enableClosedSidebar', value: !enableClosedSidebar })
  }

  return (
    <Fragment>
      <div className="app-header__logo">
        <div className="logo-src"></div>
        <div className="header__pane ml-auto">
          <div onClick={toggleEnableClosedSidebar}>
            <Hamburger
              active={!enableClosedSidebar}
              type="elastic"
              onClick={() => {
                toggleEnableClosedSidebar()
              }}
            />
          </div>
        </div>
      </div>
      <AppMobileMenu />
    </Fragment>
  )
}
