import styled from 'styled-components'

export const FooterLogin = styled.div`
  width: 100%;
  margin: 10px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  color: var(--gray-dark);
  font-weight: 100;

  > div:nth-child(1) {
    margin-bottom: 8px;
  }
`
