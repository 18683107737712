import { Fragment, React } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import cx from 'classnames'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'
import PerfectScrollbar from 'react-perfect-scrollbar'

import AppLogo from '../AppLogo'
import AppNav from '../AppNav/VerticalNavWrapper'

export default function Element() {
  const dispatch = useDispatch()
  const enableMobileMenu = useSelector((state) => state.enableMobileMenu)
  let { backgroundColor = 'bg-royal sidebar-text-light', enableBackgroundImage = false, enableSidebarShadow = true, backgroundImageOpacity = 'opacity-06' } = {}

  function toggleMobileSidebar() {
    dispatch({ type: 'enableMobileMenu', value: !enableMobileMenu })
  }

  return (
    <Fragment>
      <div className="sidebar-mobile-overlay" onClick={toggleMobileSidebar} />
      <ReactCSSTransitionGroup
        component="div"
        className={cx('app-sidebar', backgroundColor, { 'sidebar-shadow': enableSidebarShadow })}
        transitionName="SidebarAnimation"
        transitionAppear={true}
        transitionAppearTimeout={1500}
        transitionEnter={false}
        transitionLeave={false}
      >
        <AppLogo />
        <PerfectScrollbar>
          <div className="app-sidebar__inner">
            <AppNav />
          </div>
        </PerfectScrollbar>
        <div
          className={cx('app-sidebar-bg', backgroundImageOpacity)}
          style={{
            backgroundImage: enableBackgroundImage ? 'url("https://concilie.com.br/static/default/image/backgrndDepth.png")' : null,
          }}
        ></div>
      </ReactCSSTransitionGroup>
    </Fragment>
  )
}
