import React, { useState } from 'react'
import { toast, Bounce } from 'react-toastify'

import { RecuperarSenha } from '../../../Service/ApiService'
import { ContainerForm, Input, Button, CardLink, Link, Title } from '../styles'

export default function Element() {
  const [values, setValues] = useState({ email: '' })

  function onChange(ev) {
    const { name, value } = ev.target
    setValues({ ...values, [name]: value })
  }

  function onSubmit(ev) {
    ev.preventDefault()

    if (values.email.length < 6) {
      toast('Informe um e-mail valido', { type: 'warning', transition: Bounce, closeButton: true, autoClose: 5000, position: 'top-right' })
      return
    }
    Recuperar(values.email)
  }

  async function Recuperar(email) {
    try {
      const req = await RecuperarSenha(email)
      if (req.result) {
        toast('Lhe enviamos um e-mail para recuperação de sua conta!', { type: 'success', transition: Bounce, closeButton: true, autoClose: 5000, position: 'top-right' })

        setTimeout(function () {
          window.location.href = '/#/Login'
        }, 5000)
      } else {
        toast(req.message, { type: 'error', transition: Bounce, closeButton: true, autoClose: 5000, position: 'top-right' })
      }
    } catch (error) {
      toast(`${error}`, { type: 'error', transition: Bounce, closeButton: true, autoClose: 5000, position: 'top-right' })
    }
  }

  return (
    <ContainerForm>
      <Title>Informe seu e-mail, para recuperar seus dados de acesso</Title>
      <Input type="email" name="email" placeholder="E-mail" onChange={onChange} value={values.email} />
      <Button onClick={onSubmit}> Enviar </Button>

      <CardLink>
        <Link href="/#/Login">Já tem uma conta? Entre!</Link>
      </CardLink>
    </ContainerForm>
  )
}
